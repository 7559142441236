<template>
  <div class="main">
    <div class="listBox">
      <div class="itemBox" @click="toChat">
        <img class="active" src="https://newoss.zhulong.com/forum/202405/14/11/1715656868804581.png" alt="">
        <div class="right">
          <div class="title">英语老师</div>
          <div class="tags">多语言</div>
          <div class="des">精通读语言，上知天文，下知地理，上知天文下知地理</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    toChat(){
      this.$router.push({
        path: 'speechChat'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main{
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  background: #f7f7f7;
  min-height: calc(100vh - 40px);
}
.listBox{
  width: 92%;
  margin: 0 auto;
  .itemBox{
    height: calc(260px - 60px);
    padding: 20px 30px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    .active{
      height: 100%;
      border-radius: 10px;
      margin-right: 20px;
    }
    .right{
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-start;
      .title{
        font-size: 36px;
        color: #333;
        font-weight: 600;
      }
      .tags{
        border: 1px solid #ee2e2e;
        border-radius: 10px;
        display: inline-block;
        padding: 4px 20px;
        background: #FEEAE9;
        color: #ee2e2e;
      }
      .des{
        color: #666;
      }
    }
  }
}
</style>
